import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  DateFormComponent,
  DateFormModule,
} from '@jfw-library/shared/common-components/form-fields';
import { getMaxDateForEvent } from 'business-logic';
import { Event } from 'common-types';
import { ObservedValueOf } from 'rxjs';

interface DateForm {
  eventDate?: ObservedValueOf<DateFormComponent['formReady']>;
}

@Component({
  selector: 'app-event-date',
  templateUrl: './event-date.component.html',
  styleUrls: ['./event-date.component.scss'],
  standalone: true,
  imports: [DateFormModule, MatButtonModule, ReactiveFormsModule],
})
export class EventDateComponent implements OnInit, AfterViewChecked {
  @Input() event!: Event;
  @Output() setEvent = new EventEmitter<Event>();
  @Output() setStepNumber = new EventEmitter<number>();

  minDate = new Date();
  maxDate = getMaxDateForEvent();
  eventDate = 0;

  reactiveForm: FormGroup<DateForm> = new FormGroup({});

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.minDate.setHours(0, 0, 0, 0);
    this.eventDate = this.event.eventDate;
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  setDate(date: number | undefined) {
    if (date !== undefined) {
      this.eventDate = date;
    }
  }

  addChildForm<key extends keyof DateForm>(
    name: key,
    group: Exclude<DateForm[key], undefined>
  ) {
    this.reactiveForm.setControl(name, group);
  }

  onSubmit() {
    if (this.event === undefined) {
      return;
    }

    if (this.reactiveForm.valid) {
      this.event.eventDate = this.eventDate;
      this.setEvent.emit(this.event);
      this.setStepNumber.emit(4);
    }
  }
}
