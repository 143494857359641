<div>
  <h3>Will your event require orders for an individual or group?</h3>
  <button
    data-cy="Individual"
    mat-flat-button
    color="primary"
    (click)="onClick('single')"
  >
    Individual
  </button>
  <button
    data-cy="Group"
    mat-flat-button
    color="primary"
    (click)="onClick('group')"
  >
    Group
  </button>
</div>
