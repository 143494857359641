import { style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  AuthV2Service,
  CartService,
  SignInModalComponent,
} from '@jfw-library/ecommerce/core';
import { AfterSignIn, Event, EventType } from 'common-types';
import { Subscription, firstValueFrom, take } from 'rxjs';
import { EcomEventService } from '../../../../core/services/ecom-event/ecom-event.service';
import { modalSettings } from '../../../../settings/modal-settings/modal-settings';
import { EventDateComponent } from './event-date/event-date.component';
import { EventNameComponent } from './event-name/event-name.component';
import { IndividualGroupComponent } from './individual-group/individual-group.component';
import { OnlineInStoreComponent } from './online-in-store/online-in-store.component';

@Component({
  selector: 'app-get-started-modal',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    EventDateComponent,
    EventNameComponent,
    IndividualGroupComponent,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    OnlineInStoreComponent,
  ],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({
          color: 'red',
          opacity: 0,
        }),
      ]),
      transition(':leave', [
        style({
          color: 'blue',
          transform: 'translateX(0px)',
        }),
      ]),
    ]),
  ],
})
export class GetStartedModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public stepNumber = 1;
  public creatingEvent = false;

  public event: Event = {
    id: '',
    looks: [],
    members: [],
    admins: [],
    eventName: '',
    eventType: EventType.Wedding, // Default to wedding because '' is not a valid event type.  Might need to re-factor this.
    eventDate: 0,
  };

  private ecomEventService = inject(EcomEventService);
  private authV2Service = inject(AuthV2Service);

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<GetStartedModalComponent>,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.ecomEventService.getStartedEvent$.pipe(take(1)).subscribe({
        next: (event) => {
          if (event) {
            this.event = event;
            this.setStepNumber(5);
          }
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setEvent(event: Event): void {
    this.event = event;
  }

  public async setStepNumber(stepNumber: number): Promise<void> {
    this.stepNumber = stepNumber;

    if (this.stepNumber === 5) {
      const isAnon = !this.authV2Service.isLoggedIn;

      if (isAnon) {
        this.ecomEventService.getStartedEvent$.next(this.event);
        this.dialogRef.close();
        await firstValueFrom(
          this.dialog
            .open(SignInModalComponent, {
              data: { afterSignIn: AfterSignIn.CreateGetStartedEvent },
              autoFocus: false,
              disableClose: true,
              ...modalSettings,
            })
            .afterClosed()
        );
        // if (modalStatus.success) {
        //   const isLoggedIn = this.authV2Service.isLoggedIn;
        //   this.authV2Service.currentUser;
        //   const anonEvent = localStorage.getItem('anonEvent');
        //   if (anonEvent && isLoggedIn) {
        //     // this.authV1Service.getUser().then(() => {
        //     localStorage.removeItem('anonEvent');
        //     const event: Event = JSON.parse(anonEvent);
        //     const parsed = EventSchema.safeParse(event);
        //     if (!parsed.success) {
        //       console.error('Error parsing event', parsed.error);
        //       alert(
        //         'Sorry there was an error creating your event. Please try again or contact Customer Service.'
        //       );
        //       return;
        //     }
        //     this.ecomEventService
        //       .createEvent(
        //         parsed.data,
        //         undefined,
        //         this.authV2Service.currentUser?.uid
        //       )
        //       .then(() => {
        //         resetSelectedStoreLocalStorage();
        //         this.eventService.routeToFirstStep();
        //       });
        //   }
        // }
      } else {
        console.log('Creating event...');
        this.createEvent();
      }
    }
  }

  private async createEvent(): Promise<void> {
    this.creatingEvent = true;
    try {
      await this.ecomEventService.createEvent(this.event, this.dialogRef);
      this.creatingEvent = false; // probably won't get here  because of the redirect
      this.cartService.clearCartCache();
    } catch (error) {
      console.error('Error creating event', error);
      alert(
        'Sorry there was an error creating your event. Please try again or contact Customer Service.'
      );
      this.stepNumber = 1; // reset to first step
      this.creatingEvent = false;
    }
  }
}
