<div class="modal-main">
  <div class="close-modal" style="display: flex; height: 40px">
    <div style="flex: 0 0 50%; display: flex; align-items: center">
      <button
        class="round-icon-button"
        mat-icon-button
        *ngIf="stepNumber > 1"
        (click)="stepNumber = stepNumber - 1"
      >
        <mat-icon aria-label="Back" class="round-icon-pull-right"
          >arrow_back_ios</mat-icon
        >
      </button>
      <p
        *ngIf="stepNumber > 1"
        style="flex: 0 0 90px; margin-left: -5px; font-size: 14px"
      >
        <a style="color: white !important" (click)="stepNumber = stepNumber - 1"
          >Prev</a
        >
      </p>
    </div>
    <div
      style="
        display: flex;
        flex: 0 0 50%;
        align-items: center;
        justify-content: right;
      "
    >
      <button mat-icon-button mat-dialog-close class="round-icon-button">
        <mat-icon class="round-icon-center">close</mat-icon>
      </button>
    </div>
  </div>

  <div class="modal-header-container" *ngIf="stepNumber < 2">
    <h3>Plan Your Event</h3>
  </div>

  <div class="modal-header-container" *ngIf="stepNumber > 1">
    <h3>How Would You<br />Like to Order?</h3>
  </div>

  <div class="get-started-modal-main" style="min-height: 100%">
    <mat-dialog-content>
      <app-event-name
        *ngIf="stepNumber === 1"
        [event]="event"
        (setEvent)="setEvent($event)"
        (setStepNumber)="setStepNumber($event)"
      ></app-event-name>

      <app-individual-group
        [@myInsertRemoveTrigger]
        *ngIf="stepNumber === 2"
        [event]="event"
        (setEvent)="setEvent($event)"
        (setStepNumber)="setStepNumber($event)"
      ></app-individual-group>

      <app-event-date
        [@myInsertRemoveTrigger]
        *ngIf="stepNumber === 3"
        [event]="event"
        (setEvent)="setEvent($event)"
        (setStepNumber)="setStepNumber($event)"
      >
      </app-event-date>

      <app-online-in-store
        [@myInsertRemoveTrigger]
        *ngIf="stepNumber === 4"
        [event]="event"
        (setEvent)="setEvent($event)"
        (setStepNumber)="setStepNumber($event)"
      ></app-online-in-store>

      <div *ngIf="creatingEvent" class="creating-event">
        <strong>Creating Event...</strong>
        <mat-progress-spinner
          value="100"
          mode="indeterminate"
          color="primary"
          diameter="75"
          strokeWidth="7"
        ></mat-progress-spinner>
      </div>
    </mat-dialog-content>
  </div>
</div>
