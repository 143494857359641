import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { EventService } from '@jfw-library/ecommerce/core';
import { generateUuid } from 'business-logic';
import { Event, EventMember } from 'common-types';

@Component({
  selector: 'app-individual-group',
  templateUrl: './individual-group.component.html',
  styleUrls: ['./individual-group.component.scss'],
  standalone: true,
  imports: [MatButtonModule],
})
export class IndividualGroupComponent {
  @Input() event!: Event;
  @Output() setEvent = new EventEmitter<Event>();
  @Output() setStepNumber = new EventEmitter<number>();

  constructor(public eventService: EventService) {}

  onClick(singleOrGroup: string) {
    if (this.event === undefined) {
      return;
    }
    this.event.members = [];
    if (singleOrGroup === 'single') {
      this.event.isSingleUser = true;
      const singleMember: EventMember = {
        id: generateUuid(),
        firstName: 'Single',
        lastName: 'User',
        memberRole: 'Event Goer',
      };
      this.event.members.push(singleMember);
    } else {
      this.event.isSingleUser = false;
    }
    this.setEvent.emit(this.event);
    this.setStepNumber.emit(3);
  }
}
