
  <!-- <h3>PLAN YOUR EVENT</h3> -->
  <form [formGroup]="form" class="main-form">

    <div
        style="
          text-align: left;
          width: 100%;
          margin-bottom: 10px;
          font-size: 14px;
        "
      >
      * Required
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Event Name</mat-label>
      <input matInput formControlName="eventName" required />
    </mat-form-field>
    <h4 style="margin-top: 0px">What type of event are you planning?</h4>
    <button
      data-cy="Wedding"
      mat-flat-button
      color="primary"
      type="button"
      (click)="onSubmit('Wedding')"
    >
      Wedding
    </button>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="onSubmit('Prom')"
    >
      Prom
    </button>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="onSubmit('Quinceañera')"
    >
      Quinceañera
    </button>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="onSubmit('Special Event')"
    >
      Special Event
    </button>
  </form>
  <p style="margin-bottom: 0px">
    <strong>Prefer to browse first?</strong><br />
    <strong><a (click)="onBrowse()">View Our Collection</a></strong>
  </p>

