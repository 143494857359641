import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { Event, EventType } from 'common-types';

interface EventNameForm {
  eventName: FormControl<string>;
}

@Component({
  selector: 'app-event-name',
  templateUrl: './event-name.component.html',
  styleUrls: ['./event-name.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class EventNameComponent {
  private _event: Event | undefined;
  @Input()
  set event(value: Event | undefined) {
    this._event = value;
    if (value?.eventName) {
      this.form.controls.eventName.setValue(value?.eventName);
    }
  }
  get event(): Event | undefined {
    return this._event;
  }
  @Output() setEvent = new EventEmitter<Event>();
  @Output() setStepNumber = new EventEmitter<number>();

  form = new FormGroup<EventNameForm>({
    eventName: new FormControl('', { nonNullable: true }),
  });

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<EventNameComponent>
  ) {}

  onSubmit(eventType: EventType) {
    if (this.form.valid && this.event) {
      this.event.eventName = this.form.controls.eventName.value;
      this.event.eventType = eventType;
      this.setEvent.emit(this.event);
      this.setStepNumber.emit(2);
    }
  }

  onBrowse(): void {
    this.router.navigate(['ensembles/suits-and-tuxedos']);
    this.dialogRef.close();
  }
}
